/* .page-section {
    width: 100%;
    min-height: 40vh;
    box-sizing: border-box;
    padding: 75px 0 50px 0;
} */

/* .default-background {
    background: #004364;
} */

/* .world-background {
    background: #004364;
    background-size: 100%;
    background-position: center-top;
    background-repeat: no-repeat;
    background-image: url('/images/world-view.png');
} */

/* .nav-button {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    padding-left: 60px;
    padding-right: 60px;
    width: 240px;
    padding: 10px;
    outline: none;
    border-radius: 22.5px;
    color: white;
    border: 1px solid #0CB2E7;
    background-color: #0CB2E7;
} */

/* .search-button {
    display: inline-block;
    font-weight: bold;
    font-size: 16px;
    padding-left: 60px;
    padding-right: 60px;
    width: 240px;
    padding: 10px;
    outline: none;
    border-radius: 22.5px;
    color: white;
    border: 1px solid #0CB2E7;
    background-color: #0CB2E7;
    cursor: pointer;
} */

/* body {
    font-family: Work Sans;
    font-style: normal;
}

h1 {
    color: #FFE06D;
    font-size: 42px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0px;
}

h2 {
    font-family: Work Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    line-height: 38px;
    color: #FFE06D;
}

h5 {
    font-family: Work Sans;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-align: left;
    color: #FFFFFF;
} */

p.white {
    color: white;
    text-align: center;
    font-size:16px
}

/* p.black {
    color: black;
    text-align: center;
} */

@media screen and (min-width: 600px) {
    p.white {
        font-size: 22px;
    }
    }

@media screen and (min-width: 900px) {
.getStartedCardDivStyle {
    margin-top: 50px;
}
}

input[type=text]::-ms-clear {  display: none; width : 0; height: 0; }
input[type=text]::-ms-reveal {  display: none; width : 0; height: 0; }
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }
